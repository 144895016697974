import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Button from "../components/Button";
import Typography from "../components/Typography";
import curvyLines from "../../assets/curvyLines.png";
import formLogo from "../../assets/formLogo.png";
import contactLogo from "../../assets/contactLogo.png";
import locationLogo from "../../assets/locationLogo.png";

const item = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
};

const number = {
  fontSize: 24,
  fontFamily: "default",
  color: "secondary.main",
  fontWeight: "medium",
};

const image = {
  height: 120,
  my: 4,
};

export default function HowItWorks() {
  return (
    <Box
      component="section"
      sx={{ display: "flex", bgcolor: "secondary.light", overflow: "hidden" }}
    >
      <Container
        sx={{
          mt: 10,
          mb: 15,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src={curvyLines}
          alt="curvy lines"
          sx={{
            pointerEvents: "none",
            position: "absolute",
            top: -180,
            opacity: 0.7,
          }}
        />
        <Typography
          variant="h4"
          marked="center"
          component="h2"
          sx={{ mb: 4, textAlign: "center" }}
        >
          Hur det fungerar
        </Typography>
        <div>
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={number}>1.</Box>
                <Box
                  component="img"
                  src={formLogo}
                  alt="form illustration image"
                  sx={image}
                />
                <Typography variant="h5" align="center">
                  Fyll i formuläret nedan med information om din defekta bil,
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={number}>2.</Box>
                <Box component="img" src={contactLogo} alt="graph" sx={image} />
                <Typography variant="h5" align="center">
                  Vi kontaktar dig inom kort för att diskutera din begäran och
                  ge dig ett preliminärt bud på din bil.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={number}>3.</Box>
                <Box
                  component="img"
                  src={locationLogo}
                  alt="clock"
                  sx={image}
                />
                <Typography variant="h5" align="center">
                  {"Vi hämtar bilen "}
                  <br />
                  {"Vi betalar dig direkt vid hämtning av bilen."}
                  <br />
                  {"Vi tar hand om allt."}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
        <Button
          color="secondary"
          size="large"
          variant="contained"
          component="a"
          href="#form"
          sx={{ mt: 8 }}
        >
          Sälj Nu
        </Button>
      </Container>
    </Box>
  );
}
