import * as React from "react";
import { Field, Form, FormSpy } from "react-final-form";
import Box from "@mui/material/Box";
import Typography from "../components/Typography";
import FormView from "./FormView";
import { email, required } from "../components/form/validation";
import RFTextField from "../components/form/RFTextField";
import FormButton from "../components/form/FormButton";
import FormFeedback from "../components/form/FormFeedback";
import withRoot from "../withRoot";
import BasicModal from "./Modal";

function ContactUs() {
  const [sent, setSent] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const validate = (values) => {
    const errors = required(
      ["email", "name", "phoneNumber", "registreringsnummer"],
      values
    );

    if (!errors.email) {
      const emailError = email(values.email);
      if (emailError) {
        errors.email = emailError;
      }
    }

    return errors;
  };

  const handleSubmit = (values, form) => {
    setSent(!sent);
    const msg = `Hej, <br/> Du fick ett nytt mail från ${values.name} <br/><br/> Nummer: ${values.phoneNumber} <br/> Email: ${values.email} <br/> Reg: ${values.registreringsnummer} <br/><br/> Kommentar: ${values.comment}`;
    window.Email.send({
      SecureToken: "bfcb5112-b3c3-4832-bdf3-148bfec7f0c7",
      To: "support@xn--sljminbilab-l8a.se",
      From: "hassan.obeid.ho89@gmail.com",
      Subject: "Ny E-post",
      Body: msg,
    }).then(() => {
      form.reset();
      handleOpen();
      setSent(false);
    });
  };

  return (
    <section id="form">
      <FormView>
        <React.Fragment>
          <Typography
            id="form"
            variant="h3"
            gutterBottom
            marked="center"
            align="center"
          >
            Sälj Din Bil Nu
          </Typography>
        </React.Fragment>
        <Form
          onSubmit={handleSubmit}
          subscription={{ submitting: true }}
          validate={validate}
        >
          {({ handleSubmit, submitting }) => (
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 6 }}
            >
              <Field
                fullWidth
                size="large"
                component={RFTextField}
                disabled={submitting || sent}
                required
                name="name"
                autoComplete="name"
                label="Namn"
                type="text"
                margin="normal"
              />
              <Field
                autoComplete="email"
                component={RFTextField}
                disabled={submitting || sent}
                fullWidth
                label="E-post"
                margin="normal"
                name="email"
                required
                size="large"
              />
              <Field
                fullWidth
                size="large"
                component={RFTextField}
                disabled={submitting || sent}
                required
                name="phoneNumber"
                // autoComplete="current-password"
                label="Telefonnummer"
                type="tel"
                margin="normal"
              />
              <Field
                autoComplete="email"
                component={RFTextField}
                disabled={submitting || sent}
                fullWidth
                label="Registreringsnummer"
                margin="normal"
                name="registreringsnummer"
                required
                size="large"
              />
              <Field
                fullWidth
                size="large"
                component={RFTextField}
                disabled={submitting || sent}
                name="comment"
                autoComplete="name"
                label="Kommentar"
                type="text"
                margin="normal"
              />
              <FormSpy subscription={{ submitError: true }}>
                {({ submitError }) =>
                  submitError ? (
                    <FormFeedback error sx={{ mt: 2 }}>
                      {submitError}
                    </FormFeedback>
                  ) : null
                }
              </FormSpy>
              <FormButton
                sx={{ mt: 3, mb: 2 }}
                disabled={submitting || sent}
                size="large"
                color="secondary"
                fullWidth
              >
                Sälj bilen
              </FormButton>
            </Box>
          )}
        </Form>
      </FormView>
      <BasicModal
        handleClose={handleClose}
        handleOpen={handleOpen}
        open={open}
      />
    </section>
  );
}

export default withRoot(ContactUs);
