import * as React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";

const item = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  px: 5,
};

export default function Footer() {
  return (
    <Typography
      component="footer"
      sx={{ display: "flex", bgcolor: "secondary.light" }}
    >
      <Container sx={{ my: 8 }}>
        <Box sx={item}>
          ©
          <Link color="inherit" href="http://xn--sljminbilab-l8a.se/">
            säljminbilab.se
          </Link>
        </Box>
      </Container>
    </Typography>
  );
}
