import * as React from "react";
import NavBar from "./modules/views/NavBar";
import Footer from "./modules/views/Footer";
import Hero from "./modules/views/Hero";
import OurValues from "./modules/views/OurValues";
import HowItWorks from "./modules/views/HowItWorks";
import withRoot from "./modules/withRoot";
import ContactUs from "./modules/views/ContactUs";

function App() {
  return (
    <>
      <NavBar />
      <Hero />
      <OurValues />
      <HowItWorks />
      <ContactUs />
      <Footer />
    </>
  );
}

export default withRoot(App);
