import * as React from "react";
import Button from "../components/Button";
import Typography from "../components/Typography";
import HeroLayout from "./HeroLayout";
import heroImg from "../../assets/hero.jpg";

export default function Hero() {
  return (
    <div id="home">
      <HeroLayout
        sxBackground={{
          backgroundImage: `url(${heroImg})`,
          backgroundColor: "#7fc7d9",
          backgroundPosition: "center",
        }}
      >
        <img
          id="home"
          style={{ display: "none" }}
          src={heroImg}
          alt="increase priority"
        />
        <Typography color="inherit" align="center" variant="h2" marked="center">
          Sälj Din Bil
        </Typography>
        <Typography
          color="inherit"
          align="center"
          variant="h5"
          sx={{ mb: 4, mt: { xs: 4, sm: 10 } }}
        >
          Värdera din bil gratis Vi köper alla slags bilar oavsett skick. Alltid
          högsta pris.
        </Typography>
        <Button
          color="secondary"
          variant="contained"
          size="large"
          component="a"
          href="#form"
          scroll-behavior="smooth"
          sx={{ minWidth: 200 }}
        >
          Sälj Nu
        </Button>
        <Typography variant="body2" color="inherit" sx={{ mt: 2 }}>
          upptäck upplevelsen
        </Typography>
      </HeroLayout>
    </div>
  );
}
