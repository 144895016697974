import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";
import curvyLines from "../../assets/curvyLines.png";
import value1 from "../../assets/value1.png";
import value2 from "../../assets/value2.png";
import value3 from "../../assets/value3.png";

const item = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
};

function OurValues() {
  return (
    <Box
      component="section"
      sx={{ display: "flex", overflow: "hidden", bgcolor: "secondary.light" }}
    >
      <Container sx={{ mt: 8, mb: 10, display: "flex", position: "relative" }}>
        <Box
          component="img"
          src={curvyLines}
          alt="curvy lines"
          sx={{ pointerEvents: "none", position: "absolute", top: -180 }}
        />
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={value1}
                alt="suitcase"
                sx={{ height: 100, zIndex: 1 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Vi köper dina problem!
              </Typography>
              <Typography variant="h5">
                {"sälj din defekta bil till oss idag"}

                {", vi gör det enkelt för dig!"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={value2}
                alt="graph"
                sx={{ height: 100, zIndex: 1 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Sälj din bil till oss!
              </Typography>
              <Typography variant="h5">
                {"Få snabb betalning, "}

                {"Hämtning inom 24h. Pengarna på kontot direkt"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={value3}
                alt="clock"
                sx={{ height: 100, zIndex: 1 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Snabbt och enkelt
              </Typography>
              <Typography variant="h5">
                {" Vi arbetar med digitala överföringar."}
                {"Du får alltid ett dagsaktuellt och unikt bud."}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default OurValues;
